import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'znb-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  translatetitle$: Subscription;
  constructor(private translate: TranslateService) { }

  types: any[] = [];

  ngOnInit() {
    scrollTo(0, 0);
    this.translatetitle$ =
      this.translate.onLangChange.subscribe(e => {
        this.getTextInitial();
      });

      this.getTextInitial();
  }

  getTextInitial(){
    this.translate.get('home.first_fold.title').subscribe((text: string) => {      
      this.types = text.split('|');
    });
  }


  ngOnDestroy(): void {
    if (this.translatetitle$) {
      this.translatetitle$.unsubscribe();
    }
  }
}
