import { Component, OnInit, AfterViewInit } from '@angular/core';

import * as $ from 'jquery';
import 'bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'znb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
 

  lagSelect: string = 'es';
  href: string = '';
  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((url: NavigationEnd) => {
      if (url.url) {
        this.href = url.url;
      }
    });
  }

  changelanguage(lang) {
    this.lagSelect = lang;
    this.translate.use(lang);
  }

  ngAfterViewInit(): void {
    $('.navbar-nav >a').on('click', function(){
      $('.navbar-collapse').collapse('hide');
  });
  }


}
