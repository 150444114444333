import { Component, OnInit } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'znb-alert-modal',
  templateUrl: './alert-modal.component.html'
})
export class AlertModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('.js-modal-message').modal('hide');
  }

}
