import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, OnDestroy } from '@angular/core';

@Component({
  selector: 'znb-txt-type',
  templateUrl: './txt-type.component.html',
  styleUrls: ['./txt-type.component.scss']
})
export class TxtTypeComponent implements OnInit, OnChanges, OnDestroy {

  @Input('data-type') dataTypes: string[] = [];
  @Input('data-period') dataPeriod: number = 0;


  toRotate: any[];
  period: number;
  isDeleting: boolean = false;
  loopNum: number = 0;
  text: string = '';
  timeRef: any;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {   
  }

  ngOnChanges(changes: any): void {

    if (changes && changes.dataTypes) {
      this.toRotate = changes.dataTypes.currentValue;
    }

    if (changes && changes.dataPeriod) {
      this.period = changes.dataPeriod.currentValue; 
    }

    if (this.toRotate.length > 0) {
      if (this.timeRef) { clearTimeout(this.timeRef) }
      this.tick();
    }
  }

  tick() {   
    const i = this.loopNum % this.toRotate.length;
    const fulltext = this.toRotate[i];

    if (this.isDeleting) {
      this.text = fulltext.substring(0, this.text.length - 1);
    } else {
      this.text = fulltext.substring(0, this.text.length + 1)
    }

    this.elementRef.nativeElement.innerHTML = `<span class="wrap">${this.text}</span>`;

    const that = this;
    let delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.text === fulltext) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.text === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    this.timeRef = setTimeout(function () {
      that.tick();
    }, delta);


  }

  ngOnDestroy(): void {
    if (this.timeRef) {
      clearTimeout(this.timeRef)
    }

    
  }

}
