
import { Injectable } from '@angular/core';
import { Contact } from 'src/app/models/contact';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private http: HttpClient
  ) {

  }
  sendInfo(contact: Contact) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    })

    return this.http.post(`${environment.apiURL}/email`, contact, { headers });
  }
}
