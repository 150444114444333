import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from './services/contact.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

import $ from 'jquery';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'znb-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  submitingForm = false;
  showError = false;

  constructor(
    private fb: FormBuilder,
    private contactServie: ContactService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit() {
    scrollTo(0, 0);

    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact_number: ['', [Validators.required, Validators.maxLength(10)]],
      interested_in: ['', Validators.required],
      message: ['', [Validators.required, Validators.maxLength(300)]],
      terms_and_conditions: [false, Validators.requiredTrue]
    });
  }

  sendInfo() {
    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched()
      return;
    }

    this.submitingForm = true;

    this.recaptchaV3Service.execute('importantAction').subscribe((token) => {
      const data = {
        contact_number: this.contactForm.value.contact_number,
        email: this.contactForm.value.email,
        interested_in: this.contactForm.value.interested_in,
        message: this.contactForm.value.message,
        name: this.contactForm.value.name,
        terms_and_conditions: this.contactForm.value.terms_and_conditions,
        recaptcha_token: token
      };

      this.contactServie.sendInfo(data).subscribe(() => {
        $('.js-modal-message').modal('show');
        this.contactForm.reset();
        Object.keys(this.contactForm.controls).forEach(key => {
            this.contactForm.get(key).setErrors(null) ;
        });
        this.submitingForm = false;
      }, error => {
        this.submitingForm = false;
        this.showError = true;

        setTimeout(() => {
          this.showError = false;
        }, 4000);
      });
    });
  }
}
