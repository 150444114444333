import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';


const routes: Routes = [

  {
    path: 'inicio',
    component: HomeComponent
  },
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
    
  },
  {
    path: 'nosotros',
    component: AboutUsComponent
  },
  {
    path: 'hablemos',
    component: ContactComponent
  },
  {
    path: 'terminos-y-condiciones',
    component: TermsAndConditionsComponent
  },
  {
    path: '**',
    redirectTo: 'inicio',
    pathMatch: 'full'

  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
